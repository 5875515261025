import React from "react";
import { Router } from "react-router-dom";


import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";

import AppRouter from "./components/AppRouter";

initFontAwesome();

const App = () => {
  const {isAuthenticated,isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        
          <AppRouter></AppRouter>
        
        <Footer />
      </div>
    </Router>
  );
};

export default App;
