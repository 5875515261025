import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import {
    useMeetingManager,
    useLocalVideo,
    useAudioVideo,
    ControlBar,
    ControlBarButton,
    Meeting,
    LeaveMeeting,
    AudioInputControl,
    DeviceLabels,
    VideoTileGrid,
    Remove,
    VideoInputControl,
    AudioOutputControl,
    MeetingStatus,
    useMeetingStatus,
    LocalVideo,
} from 'amazon-chime-sdk-component-library-react';

import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';

import { Amplify, API, Auth } from 'aws-amplify';
import { fetchAmplifyConfig } from '../../AmplifyConfig';
import {AmplifyConfig, env} from '../../../../utils/constants';
import { useAuth0 } from "@auth0/auth0-react";
export const WeEvaluateMetting = React.forwardRef((props, ref) => {
    const {  user} = useAuth0();
    const meetingManager = useMeetingManager();
    const meetingStatus = useMeetingStatus();
    const [meetingId, setMeetingId] = useState('');
    const [attendeeId, setAttendeeId] = useState('');
    const [transcripts, setTranscripts] = useState([]);
    const [lines, setLine] = useState([]);
    const [currentLine, setCurrentLine] = useState('');
    const audioVideo = useAudioVideo();

    const { toggleVideo } = useLocalVideo();
    
    useEffect(() => {
     
        Amplify.configure(AmplifyConfig);
       // Amplify.Logger.LOG_LEVEL = 'DEBUG';
        console.log('fetchAmplifyConfig.API',AmplifyConfig.API);
        async function tog() {
            if (meetingStatus === MeetingStatus.Succeeded) {
                await toggleVideo();
                props.cameraIntiated(true);
            }
        }
        tog();
    }, [meetingStatus,AmplifyConfig]);

    useEffect(() => {
        if (transcripts) {
            if (transcripts.results !== undefined) {
                console.log(`Transcript Results:  ${JSON.stringify(transcripts.results)}`);
                props.getTranscripts(transcripts)
                 if (!transcripts.results[0].isPartial) {
                    addToCandidateExplanation(transcripts.results[0].alternatives[0].transcript)
               
                    setLine((lines) => [
                        ...lines,
                        `${transcripts.results[0].alternatives[0].items[0].attendee.externalUserId}: ${transcripts.results[0].alternatives[0].transcript}`,
                    ]);
                    //localStorage.setItem('candidateExplanation', lines);
                    setCurrentLine('');
                } else {
                    localStorage.setItem('currentLine', transcripts.results[0].alternatives[0].transcript);
                    setCurrentLine(
                        `${transcripts.results[0].alternatives[0].items[0].attendee.externalUserId}: ${transcripts.results[0].alternatives[0].transcript}`,
                    );
                }
            }
        }
    }, [transcripts]);

    useEffect(() => {
        if (audioVideo) {
            audioVideo.transcriptionController?.subscribeToTranscriptEvent((transcriptEvent) => {
                setTranscripts(transcriptEvent);
            });
        }
    }, [audioVideo]);

    const JoinButtonProps = {
        icon: <Meeting />,
        onClick: (event) => handleJoin(event),
        label: 'Join',
    };

    const LeaveButtonProps = {
        icon: <LeaveMeeting />,
        onClick: (event) => handleLeave(event),
        label: 'Leave',
    };

    const EndButtonProps = {
        icon: <Remove />,
        onClick: (event) => handleEnd(event),
        label: 'End',
    };

    const handleLeave = async (event) => {
        await meetingManager.leave();
    };

    const handleEnd = async (event) => {

        //  event.preventDefault();
        try {
            await API.post('meetingApi', 'end', { body: { meetingId: meetingId } });
 
        } catch (err) {
            console.log(err);
        }
    };

    const handleJoin = async (event) => {
        // event.preventDefault();
        // const email = (await Auth.currentUserInfo()).attributes.email;
        const hostname = window.location.hostname;
        const appEnv = env[hostname] || "dev";  
        const email = user.email;
        console.log(email);
        try {
            const joinResponse = await API.post('meetingApi', 'meeting', {
                body: { email: email, jobId: props.questionReqObject.jobpId, candidateId: props.questionReqObject.candidateId,appEnv:appEnv },
            });
            const meetingSessionConfiguration = new MeetingSessionConfiguration(
                joinResponse.Meeting,
                joinResponse.Attendee,
            );

            const options = {
                deviceLabels: DeviceLabels.AudioAndVideo,
            };

            await meetingManager.join(meetingSessionConfiguration, options);
            await meetingManager.start();
            meetingManager.invokeDeviceProvider(DeviceLabels.AudioAndVideo);
            setMeetingId(joinResponse.Meeting.MeetingId);
            setAttendeeId(joinResponse.Attendee.AttendeeId);
        } catch (err) {
            console.log(err);
        }
    };
    useImperativeHandle(ref, () => ({
        handleJoin: handleJoin,
        handleEnd: handleEnd,
    }));
    const  addToCandidateExplanation =(transcripts) =>{
        // Get the current value of the candidateExplanation from localStorage
       
        let candidateExplanation = JSON.parse(localStorage.getItem('candidateExplanation'));
        console.log('transcripts',transcripts,candidateExplanation)
         // Concatenate each transcript with the existing candidateExplanation value
         if (candidateExplanation) {
            candidateExplanation.push(transcripts);
        } else {
            candidateExplanation = [transcripts];
        }
        
        // Store the concatenated explanation back in localStorage
        localStorage.setItem('candidateExplanation', JSON.stringify(candidateExplanation));
    }
    return (
        <div>
            <div style={{ height: '200px', width: '250px' }}>
                <VideoTileGrid />
            </div>

            <div style={{ height: '50px', width: '320px', display: 'inline' }}>
                <div style={{ display: 'none' }}>
                    <ControlBarButton {...JoinButtonProps} />
                    <ControlBarButton {...LeaveButtonProps} />
                    <ControlBarButton {...EndButtonProps} />
                </div>
                <ul style={{ listStyleType: 'none', padding: 0, display: 'flex' }}>
                    <li style={{ display: 'inline', marginRight: '10px' }}>
                     
                        <AudioInputControl />
                    </li>
                    <li style={{ display: 'inline', marginRight: '10px' }}>
                        <AudioOutputControl />
                    </li>
                    <li style={{ display: 'inline' }}>
                      
                        <VideoInputControl />
                    </li>
                </ul>
            </div>
        </div>
    );
});
