// candidateService.js

import axios from "axios";

import weevaluateagent from "./weevaluateagent";

const API_BASE_URL = "/interviewgenius/v1"; // Replace with your API base URL

const candidateService = {
  // Create a new candidate

  // Generate a report based on candidate ratings
  getRTToken: async () => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `/rttoken`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateInterviewsStatus: async (jobId, candidateId,status) => {
    try {
      const response = await weevaluateagent.requestsA.put(
        `${API_BASE_URL}/candidates/update-interview-status/${jobId}/${candidateId}`,{
          status:status
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getQuestionIds: async (jobId, candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${candidateId}/questionResults/${jobId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getQuestionObject: async (jobId, candidateId, qId) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${candidateId}/questionResults/${jobId}/${qId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchInterviews: async (email) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/verify/scheduled-interviews?email=${email}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  
  verifyCodeForInterviews: async (email, code) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("code", code);
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/verify/code/scheduled-interviews`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  saveAudioRecordingToS3 :async(jobId,candidateId,questionId,audioChunks) => {
    try {  
      const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
      const formData = new FormData();
      formData.append('audio', audioBlob, 'recording.mp3');
      formData.append('questionId', questionId);
      formData.append('candidateId', candidateId);
      formData.append('jobId', jobId);
      const response = await  weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/save-audio-s3`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;

    }catch(err){
      throw err
    }
    
  },
  saveVideoRecordingToS3 :async(jobId,candidateId,questionId,videoChunks) => {
    try {  
      const videoBlob = new Blob(videoChunks, { type: 'video/mp4' }); // or 'video/mp4'
      const formData = new FormData();
      formData.append('video', videoBlob, 'recording.mp4'); // or 'recording.mp4'
      formData.append('questionId', questionId);
      formData.append('candidateId', candidateId);
      formData.append('jobId', jobId);
      const response = await  weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/save-video-s3`,
        formData
        /*formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }*/
      );

      return response;

    }catch(err){
      throw err
    }
    
  },
  readQuestion: async (question) => {
    // Assuming setLoading exists elsewhere in your UI management

    try {
      const formData = new FormData();
      formData.append("question", question);

      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/synthesize`,
        formData
      )

      // Handle the response from your text-to-speech service
    console.log(response)
  //  const audioData = await response.blob(); // Or a different format
    
    return response;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching synthesized audio:", error);

      throw error; // Allow the caller to manage the error
    }
  },
  
 saveCandidateExplanation : async (
  candidateId,
  jobId,
  questionId,
  explanation
) => {
  try {
    const formData = new FormData();
    console.log("explanation", explanation);
    formData.append("answer", explanation);
    const response = await weevaluateagent.requestsA.post(
      `${API_BASE_URL}/candidates/${candidateId}/questionResults/${jobId}/${questionId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error posting answer:", error);
    return error;
  }
},
};

export default candidateService;
