import React, { useState, useRef, useEffect } from 'react';
import { Stack, TextField, PrimaryButton, Image, IconButton } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../services/weevaluateagent";
import candidateService from "../../services/candidateService";
const ConfirmProfilePage = ({ history }) => {
  const { tokenId } = useParams();
  const [candidate,setCandidate] = useState("")
  const [acknowledge, setAcknowledge] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const [evaluationData,setEvaluationData] =useState(null)

  useEffect(() => {
    let jsonObject=extractDataJSON(tokenId)
    const candidate=JSON.parse(localStorage.getItem('candidate'))
    jsonObject.name=candidate.fullName;
    jsonObject.email=candidate.email;
    jsonObject.phone=candidate.phone;
    jsonObject.evaluationTime=candidate.evaluationTime;
    console.log('jsonObject',jsonObject)
    setEvaluationData(jsonObject)
   
  }, [getAccessTokenSilently,tokenId]);

  const extractDataJSON = (token) => {
    const parts = token.split("$");
    if (parts.length !== 2) {
      return null; // Invalid format
    }
    const decodedData = atob(parts[1]); // Base64 decoding
    
   
    return JSON.parse(decodedData);
  };
  const handleSubmit = async () => {
    const authToken = await getAccessTokenSilently();
    superagent.setToken(authToken);
    const response = await candidateService.updateInterviewsStatus(evaluationData.jobId, evaluationData.candidateId,'Details Confirmed',);
    history.push(`/interview/evaluation/${tokenId}`);
  };
 

  const selectAcknowledge = () => {
if(!acknowledge){
    setAcknowledge(true);
}else{
  setAcknowledge(false);
}
    
  };

  useEffect(() => {
    setCandidate(JSON.parse(localStorage.getItem('candidate')))
    console.log(candidate)
   

  }, []);
  return (
   
    <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { margin: '0 auto', width: '100%'} }}>
    <Card tokens={{ maxWidth: '90%', maxHeight: 'auto', childrenMargin: 20 }}>
      <Card.Section>
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign="space-between">
          {/* Column for the profile info and selfie confirmation */}
          <Stack.Item>
            <Stack tokens={{ childrenGap: 15 }}>
             
              <sapn><b style={{paddingRight:'10px'}}>Name</b>{candidate.fullName}</sapn>
              <sapn><b style={{paddingRight:'10px'}}>Email</b>{candidate.email}</sapn>
              <sapn><b style={{paddingRight:'10px'}}>Phone</b>{candidate.phone}</sapn>
              <sapn><input type='checkbox' value={acknowledge} onChange={selectAcknowledge} style={{marginRight:'10px'}}></input>Could you please acknowledge above details</sapn>
              
              {acknowledge && <PrimaryButton text="Confirm" onClick={handleSubmit} />}
            </Stack>
          </Stack.Item>
          
          
        </Stack>
      </Card.Section>
    </Card>
  </Stack>
     
  );
};

export default ConfirmProfilePage;
