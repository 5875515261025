import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as RecordRTC from "recordrtc";
import {
  increment,
  selectCount,
  fetchQuestionIdsAPI,
  selectQuestionIds,
  fetchQuestionObjectAPI,
  saveCandidateExplanationAPI,
  selectQuestion,
  questionExplanation,
} from "./EvaluatingSlice"; // Importing Redux related functions from the slice
import superagent from "../../../services/weevaluateagent";
import WeEvaluateLogger from "../../../../../utils/WeEvaluateLogger"; // Importing the logger
import TimerProgressBar from "./TimerProgressBar";
import ReadQuestionAloud from "./ReadQuestionAloud";
import { useAuth0 } from "@auth0/auth0-react";
import "./Evaluating.module.css";
import ProfileIcon from "./ProfileIcon";
import WeEvaluateCamera from "../../../components/WeEvaluateMeet/WeEvaluateCamera";
import Loading from "../../../../../components/Loading";
import candidateService from "../../../services/candidateService";
const rtTextArray = [];
export const Evaluating = React.memo(
  ({ evaluationData, handleEvaluationEnd }) => {
    const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } =
      useAuth0();
    const readQuestionAloudRef = useRef(null);
    const audioRecorderRef = useRef(null);
    const videoRecorderRef = useRef(null);
    const socket = useRef(null);
    const recorder = useRef(null);
    const [isCameraStarted, setIsCameraStarted] = useState(false);
    const [lines, setLine] = useState([]);
    const [currentLine, setCurrentLine] = useState("");
    const weEvaluateCameraRef = useRef(null);
    const count = useSelector(selectCount);
    const questionIds = useSelector(selectQuestionIds);
    const question = useSelector(selectQuestion);
    const explanation = useSelector(questionExplanation);
    const [recordedAnswers, setRecordedAnswers] = useState([]);
    const dispatch = useDispatch();
    const [initialTest, setInitialTest] = useState(false);
    const [examTime, setExamTime] = useState(
      parseInt(evaluationData.evaluationTime)
    );
    const [evaluationTimer, setEvaluationTimer] = useState(examTime * 60); // 30 minutes in seconds
    const [tolatEvaluationTimer, setTolatEvaluationTimer] = useState(
      examTime * 60
    ); // 30 minutes in seconds
    const [timer, setTimer] = useState(null); // 3 minutes in seconds
    const [questionTimer, setQuestionTimer] = useState(null);
    const [showEndButton, setShowEndButton] = useState(false);
    const [sideBar, setSideBar] = useState(true);

    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState("");


   

    const clickTimesArray = [];
    let questionTimingRecord = [];
    const questionReqObject = {
      candidateId: evaluationData.candidateId,
      jobpId: evaluationData.jobId,
    };
    let candidateExplanation = [];
    // Fetch question IDs when component mounts
    useEffect(() => {
      if (!isCameraStarted) {
      startCamera();
    
      }
      if (initialTest) {
        localStorage.setItem("candidateExplanation", JSON.stringify([]));
        console.log(evaluationData);
        if (evaluationData) dispatch(fetchQuestionIdsAPI(questionReqObject));

        WeEvaluateLogger.log(
          "Initiating Fetch Question IDs Request",
          questionReqObject
        );
       
      }
    }, [dispatch, initialTest, evaluationData, weEvaluateCameraRef]);

    // Fetch question object when question IDs are available
    useEffect(() => {
      if (questionIds.length > 0) {
        if (count === 0) {
          setTimer((examTime * 60) / (questionIds.length - 1));
          console.log(
            "(examTime * 60) / questionIds.length - 1",
            examTime * 60,
            questionIds.length - 1,
            (examTime * 60) / (questionIds.length - 1)
          );
          setQuestionTimer((examTime * 60) / (questionIds.length - 1));
          const currentTime = Date.now();
          clickTimesArray.push(currentTime);
        }
        const currentQuestionId = questionIds[count];
        questionReqObject.questionId = currentQuestionId;
        WeEvaluateLogger.log("Fetching Question Object", questionReqObject);

        dispatch(fetchQuestionObjectAPI(questionReqObject));
      }
    }, [dispatch, questionIds, count]);

    // Countdown timer for individual question
    useEffect(() => {
      if (initialTest && question) {
        const intervalId = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 0) {
              clearInterval(intervalId);
              handleTimeOut(); // Handle timeout when timer reaches zero
              return 0;
            } else if(isRecording) {
              console.log('(prevTimer === questionTimer',prevTimer,questionTimer)
              if (prevTimer === questionTimer- 2) {
                if(isRecording)
                readQuestionAloudRef.current.readQuestion(question.question);
              }
              return prevTimer - 1;
            }else{
              return prevTimer;
            }
          });
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup function
      }
    }, [question, initialTest]);

    // Countdown timer for total evaluation time
    useEffect(() => {
      if (initialTest) {
        const totalIntervalId = setInterval(() => {
          setEvaluationTimer((prevTimer) => {
            if (prevTimer <= 0) {
              clearInterval(totalIntervalId);
              handleTotalTimeOut(); // Handle total timeout when evaluation time reaches zero
              return 0;
            } else {
              return prevTimer - 1;
            }
          });
        }, 1000); // Update every second

        return () => clearInterval(totalIntervalId); // Cleanup function
      }
    }, [dispatch, questionIds, initialTest]);

    useEffect(() => {
      const handleWindowClose = (event) => {
        // Perform actions when the window is closed or refreshed
        console.log("Window closed or refreshed");
        endTranscription();
        // You can perform cleanup actions or send requests here
      };

      window.addEventListener("beforeunload", handleWindowClose);

      return () => {
        window.removeEventListener("beforeunload", handleWindowClose);
      };
    }, []);

    const generateTranscript = async () => {
      const authToken = await getAccessTokenSilently();
      superagent.setToken(authToken);
      const response = await candidateService.getRTToken();
      const data = response;
      console.log("assemblyai Message : ", data);
      if (data.error) {
        alert(data.error);
      }

      const { token } = data;

      socket.current = await new WebSocket(
        `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`
      );

      const texts = {};
      socket.current.onmessage = (voicePrompt) => {
        let msg = "";
       
        const res = JSON.parse(voicePrompt.data);
        texts[res.audio_start] = res.text;
        const keys = Object.keys(texts);
        keys.sort((a, b) => a - b);
        for (const key of keys) {
          if (texts[key]) {
            msg += ` ${texts[key]}`;
           
          
          }
        }
        setTranscript(msg);
        localStorage.setItem("newtranscripts",msg)
      };

      socket.current.onerror = (event) => {
        console.error(event);
        socket.current.close();
      };

      socket.current.onclose = (event) => {
        console.log(event);
        socket.current = null;
      };

      socket.current.onopen = () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            recorder.current = new RecordRTC(stream, {
              type: "audio",
              mimeType: "audio/webm;codecs=pcm",
              recorderType: RecordRTC.StereoAudioRecorder,
              timeSlice: 250,
              desiredSampRate: 16000,
              numberOfAudioChannels: 1,
              bufferSize: 4096,
              audioBitsPerSecond: 128000,
              ondataavailable: (blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64data = reader.result;
                  if (socket.current) {
                    socket.current.send(
                      JSON.stringify({
                        audio_data: base64data.split("base64,")[1],
                      })
                    );
                  }
                };
                reader.readAsDataURL(blob);
              },
            });
            recorder.current.startRecording();
          })
          .catch((err) => console.error(err));
      };
      setIsCameraStarted(true)
      setIsRecording(true);
    };

    const endTranscription = async () => {
      setIsRecording(false);
if(socket.current){
      socket.current.send(JSON.stringify({ terminate_session: true }));
      socket.current.close();
      console.log(prompt);
      socket.current = null;

      recorder.current.pauseRecording();
      recorder.current = null;
}
    };

    const handleTimeOut = async() => {
      endTranscription()
      const currentQuestionId = questionIds[count];
      questionReqObject.questionId = currentQuestionId;

      console.log(
        "candidateExplanation",
        localStorage.getItem("candidateExplanation")
      );
      
      questionReqObject.explanation=transcript
      if(questionReqObject.explanation!==""){
      dispatch(saveCandidateExplanationAPI(questionReqObject));
      generateTranscript()
      }
      if (count < questionIds.length - 1) {
        setTimer(questionTimer); // Reset timer for the next question
        handleCountDispatch();
       
      }
      if (count ===1) {
        const authToken = await getAccessTokenSilently();
        superagent.setToken(authToken);
       const response = await candidateService.updateInterviewsStatus(evaluationData.jobId, evaluationData.candidateId,'Interview InProgress');
      }
      if(count===questionIds.length - 1){
        handleTotalTimeOut()
      }
    };

    // Handle timeout for individual questions
    function handleCountDispatch() {
      // Get the current time
      const currentTime = Date.now();
      console.log("questionTimer", questionTimer);
      // If there are previous click times
      if (clickTimesArray.length > 0) {
        // Get the time of the last click
        const lastClickTime = clickTimesArray[clickTimesArray.length - 1];
        // Calculate the time elapsed since the last click in milliseconds
        const timeElapsed = currentTime - lastClickTime;

        // If less than 10 seconds have passed since the last click, return
        if (timeElapsed < (questionTimer - 5) * 1000) {
          console.log("Please wait before clicking again.");
          return;
        }
      }

      // Log the current time
      console.log("Button clicked at:", new Date(currentTime).toLocaleString());
      dispatch(increment());
      // Add the current time to the clickTimesArray
      clickTimesArray.push(currentTime);
      console.log(clickTimesArray);
    }

    // Handle timeout for total evaluation time
    const handleTotalTimeOut = async() => {
      // Implement your logic to handle total timeout
      stopCamera();
      setShowEndButton(true);
      setSideBar(false);
      endTranscription();
      const authToken = await getAccessTokenSilently();
      superagent.setToken(authToken);
      const response = await candidateService.updateInterviewsStatus(evaluationData.jobId, evaluationData.candidateId,'Interview Completed');
     
    };

    // Format timer into MM:SS format

    const startRecording = () => {
      console.log("I am in start Record");
    };
    const fnSetInitialTest = (event) => {
      event.preventDefault();
      localStorage.setItem("candidateExplanation", '');
      setInitialTest(true);
    };
    const cameraIntiated = (cameraStatus) => {
      console.log("cameraIntiated function in WeEvaluate Component");
      setIsCameraStarted(cameraStatus);
    };
    const getTranscripts = (transcripts) => {
      if (!transcripts.results[0].isPartial) {
        setLine((lines) => [
          ...lines,
          ` ${transcripts.results[0].alternatives[0].transcript}`,
        ]);
        if (initialTest) {
          candidateExplanation.push(
            transcripts.results[0].alternatives[0].transcript
          );
        }
        setCurrentLine("");
      } else {
        setCurrentLine(`${transcripts.results[0].alternatives[0].transcript}`);
      }
    };

    const startCamera = async () => {
      weEvaluateCameraRef.current.startWeEvaluateCamera();
      const authToken = await getAccessTokenSilently();
      superagent.setToken(authToken);
      const response = await candidateService.updateInterviewsStatus(evaluationData.jobId, evaluationData.candidateId,'Interview Initiated');
      generateTranscript();
      console.log(response)
     };
    const fnNextQuestion = () => {
      // dispatch(increment());
      handleTimeOut();
    };
    const stopCamera = () => {
      console.log("I am at stop");
      weEvaluateCameraRef.current.stopWeEvaluateCamera();
    };

    return (
      <div>
        {!isCameraStarted && <Loading />}
        <div style={{display:'none'}}>{transcript}</div>
        <div style={{ display: isCameraStarted ? "block" : "none" }}>
          <>
            <div className="video-call-container">
              <div className="main-video">
                {!showEndButton && isRecording && !initialTest && (
                  <div className="question-container">
                    <div variant="mediumPlus">Please read Above paragraph</div>{" "}
                    <br />
                    <div variant="mediumPlus">
                      {" "}
                      Hello , I'm {evaluationData.name}. You can reach me by
                      email at {evaluationData.email} or by phone at{" "}
                      {evaluationData.phone}.{" "}
                     
                    </div>
                    <div style={{ width: "100%", fontSize: "20px" }}>
                    {transcript && <div><span>{transcript}</span> <br></br>
                    <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out focus:outline-none focus:shadow-outline"
   
                        onClick={fnSetInitialTest}
                      >
                  Yes, able to see the sentence what I spoke.
                      </button>
                      </div>}
                    </div>
                  </div>
                )}
               
                {!showEndButton  && question && question.question && (
                  <>
                    <TimerProgressBar
                      timer={timer}
                      totalTimer={questionTimer}
                      timerShow={false}
                      fnNextQuestion={fnNextQuestion}
                    />
                     {isRecording ? <p className="question-container">
                      {count + 1} {question.question}
                    </p> :<p className="question-container">Getting Next question</p>}
                   

                    <TimerProgressBar
                      timer={evaluationTimer}
                      totalTimer={tolatEvaluationTimer}
                      timerShow={true}
                    />
                   {isRecording && <button onClick={fnNextQuestion}  class="m-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out focus:outline-none focus:shadow-outline"
   >Next Question</button>}
                   { /*
                    <button onClick={stopCamera}>Stop Camera</button>
                    <button onClick={endTranscription}>End Transcript</button>
                */}
                    <div style={{ width: "100%", fontSize: "20px" }}>
                      {candidateExplanation.length > 0 && candidateExplanation}
                    </div>
                  </>
                )}
                {showEndButton && (
                  <>
                    <div className="button-container">
                      <button
                        className="interview-end-button"
                        onClick={handleEvaluationEnd}
                      >
                        End Test
                      </button>
                    </div>
                    <div className="question-container">
                      Question are over Just click End Button to End the Test
                    </div>
                  </>
                )}
              </div>

              {sideBar && (
                <div className="sidebar">
                  <div className="card">
                    
                    <WeEvaluateCamera
                      ref={weEvaluateCameraRef}
                      getTranscripts={getTranscripts}
                      cameraIntiated={cameraIntiated}
                      questionReqObject={questionReqObject}
                    />
                  </div>
                  <div className="card">
                    {/* User card content goes here */}
                    {!showEndButton && question && question.question && (
                      <ReadQuestionAloud
                        question={question.question}
                        ref={readQuestionAloudRef}
                        startRecording={startRecording}
                      />
                    )}
                    <ProfileIcon letter="A" />
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    );
  }
);
